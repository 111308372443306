import axios from "@/axios";

const getChecksList = async (tenantId) => {
    let url = `/tenant-check/${tenantId}`;
    try {
        let { data } = await axios.get(url);
        return data.data
    }
    catch (error) {
        console.log(error, "Error")
    }
    // this.columns = columns;
}

const getPackagesCheck = async (packageId) => {
    const url = `/screening-package/${packageId}/check`
    try {
        const { data } = await axios.get(url)
        return data
    } catch (error) {
        console.warn("some error in fetching package checks..")
    }
}

// external_id = tenant Id
const getEmailSquencesAll = async(external_id) => {
    const url = `/email-sequence/list?external_id=${external_id}`
    try {
        const { data } = await axios.get(url)
        return data.content
    } catch (error) {
        console.warn(error.response.data.detail || "Email sequences not found")
    }
}

const getEmailSquences = async(external_id) => {
    const url = `/email-sequence/linearlist?external_id=${external_id}`
    try {
        const { data } = await axios.get(url)
        return data
    } catch (error) {
        console.warn(error.response.data.detail || "Email sequences not found")
    }
}
export { getChecksList, getPackagesCheck, getEmailSquencesAll, getEmailSquences }



